<template>
  <div class="container">
    <!--UPLOAD-->
    <form
      @submit.prevent
      enctype="multipart/form-data"
      novalidate
      v-if="isInitial || isSaving"
    >
      <div class="dropbox">
        <input
          type="file"
          ref="filesUploader"
          @click="resetUploader"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="
            filesChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "

          accept="*"
          class="input-file"
        />
        <p v-if="isInitial">
          Drag your file(s) here to begin<br />
          or click to browse
        </p>
        <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
      </div>
      <div class="images-preview">
        <div
          class="images-preview-inner"
          v-for="(file, index) in fileUrlList"
          :key="index"
          @click="removeFile(index)"
        >
          <span class="delete-overlay">X</span>
          <img
            :src="
              file.type == 'image/png'
                ? file.url
                : '/images/icons/file-empty.png'
            "
            alt="Your File"
          />
          <p>{{ file.name }}</p>
        </div>
      </div>
      <div class="upload-submit-wrap">
        <div class="wrap-login100-form-btn support-pack-btn mt-20 mb-20">
          <div class="login100-form-bgbtn"></div>
          <button class="login100-form-btn" v-on:click="uploadFiles">
            Select files
          </button>
        </div>
        <button v-on:click="this.$emit('cancelUpload')" class="upload-cancel">
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "app",
  props: {
    selectedFiles: new FormData(),
  },
  data() {
    return {
      uploadedFiles: [],
      fileList: [],
      fileUrlList: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "files",
      fileData: new FormData(),
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    resetUploader() {
      this.$refs.filesUploader.value = "";
    },
    save(fieldName) {
      // append the files to FormData
      // handle file changes
      const formData = new FormData();
      Array.from(Array(this.fileList.length).keys()).map((x) => {
        formData.append(fieldName, this.fileList[x], this.fileList[x].name);
      });
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      console.log("uploaded files: ", this.uploadedFiles);
      // upload(formData)
      //   .then((x) => {
      //     this.uploadedFiles = [].concat(x);
      //     this.currentStatus = STATUS_SUCCESS;
      //   })
      //   .catch((err) => {
      //     this.uploadError = err.response;
      //     this.currentStatus = STATUS_FAILED;
      //   });
    },
    filesChange(fieldName, fileList) {
      console.log("selected files list: ", fileList);
      if (!fileList.length) return;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.fileData.append(fieldName, fileList[x], fileList[x].name);
        var url = URL.createObjectURL(fileList[x]);
        this.fileUrlList.push({
          name: fileList[x].name,
          url: url,
          type: fileList[x].type,
        });
      });

      console.log("file list: ", this.fileList);
      console.log("URL list: ", this.fileUrlList);

      console.log(this.fileData.getAll("files"));
    },
    
    removeFile(index) {
      var files = this.fileData.getAll("files");
      this.fileData.delete("files");
      for (var i = 0; i < files.length; i++) {
        if (i == index) {
          continue;
        } else {
          this.fileData.append(this.uploadFieldName, files[i], files[i].name);
        }
      }
      this.fileUrlList.splice(index, 1);
      console.log(this.fileData.getAll("files"));
    },
    uploadFiles() {
      this.$emit("upload", this.fileData);
    },
  },
  mounted() {
    this.reset();
    this.fileData = this.selectedFiles;
    var fileList = this.selectedFiles.getAll("files");
    if (fileList.length > 0) {
      Array.from(Array(fileList.length).keys()).map((x) => {
        var url = URL.createObjectURL(fileList[x]);
        this.fileUrlList.push({
          name: fileList[x].name,
          url: url,
          type: fileList[x].type,
        });
      });
    }
  },
};
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
.images-preview {
  display: flex;
  margin-top: 15px;
}
.images-preview img {
  max-width: 70px;
  margin-right: 10px;
  border: 1px solid #000;
  padding: 8px;
  transition: all 0.3s;
}
.images-preview p {
  max-width: 75px;
  line-height: 1;
  font-size: 12px;
  color: #000;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.images-preview-inner {
  position: relative;
  cursor: pointer;
}
.delete-overlay {
  position: absolute;
  right: 5px;
  top: -10px;
  color: red;
  border: 1px solid #000;
  border-radius: 50%;
  background-color: #fff;
  padding: 3px 5px;
  line-height: 1;
  font-weight: 400;
  display: none;
}
.images-preview-inner:hover img {
  border-color: red;
}
.images-preview-inner p {
  transition: all 0.3s;
}
.images-preview-inner:hover p {
  color: red;
}
.images-preview-inner:hover .delete-overlay {
  border-color: red;
  display: block;
}
.images-preview-inner:hover .delete-overlay:hover {
  font-weight: 900;
}
.upload-submit-wrap .wrap-login100-form-btn {
  margin: 0 10px;
}
.upload-submit-wrap {
  display: flex;
  justify-content: center;
}
</style>
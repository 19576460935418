<template>
  <div id="reset">
    <div class="limiter">
      <div
        class="container-login100"
        style="background-image: url('images/attractor-bg.png')"
      >
        <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form class="login100-form validate-form" @submit.prevent>
            <span class="login100-form-title p-b-49"> Update Password </span>

            <div
              class="wrap-input100 validate-input m-b-23"
              data-validate="Password is reauired"
            >
              <span class="label-input100"></span>
              <input
                class="input100"
                type="password"
                name="password"
                id="password"
                placeholder="Enter New Password"
                v-model="password"
              />
              <span class="focus-input100" data-symbol="&#xf190;"></span>
            </div>
            <div
              class="wrap-input100 validate-input m-b-23"
              data-validate="Password is reauired"
            >
              <span class="label-input100"></span>
              <input
                class="input100"
                type="password"
                name="verify_password"
                id="verfiy_password"
                placeholder="Verify New Password"
                v-model="verify_password"
              />
              <span class="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button
                  class="login100-form-btn"
                  id="loginSubmit"
                  v-on:click="updatePassword"
                >
                  Update Password
                </button>
              </div>
              <div class="text-center validation-message p-t-8">
                {{ error_message }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="loader-overlay" v-if="loading == true">
      <Loader> </Loader>
    </div>
  </div>
</template>

<script>
import DataService from "@/api/DataService";
import router from "@/router";
import Loader from "@/components/loader.vue";


export default {
  data() {
    return {
      username: null,
      password: null,
      verify_password: null,
      error_message: "",
      loading: true,
      req: null,
      cont: null,
      acc_id: null,
      linkValidated: false,
    };
  },
  components: {
    Loader,
  },
  mounted() {
    this.loadQueryParams();
  },
  methods: {
    loadQueryParams() {
      this.req = this.$route.query.req;
      this.cont = this.$route.query.cont;
       this.validateLink();
      this.loading = false;
    },
    async validateLink(){
        var res = await DataService.validateResetLink(this.req,this.cont);
        if(res.res_code == 'link_validated'){
            this.acc_id = res.acc_id;
            this.linkValidated = true;
        }
       
    },
    async updatePassword(){
        this.error_message = "";
        console.log(this.password,this.verify_password)
        if(this.password.match(this.verify_password)){
            var res = await DataService.resetPassword(this.req,this.cont,this.password);
            console.log(res.data.res_code)
            if(res.data.res_code == 'reset_success'){
                router.push({name:"Login"});
            }else{
                this.error_message = "Password reset failed.";
                this.password = "";
                this.verify_password = "";
            }
        }else{
            this.error_message = "Password and Verify Password fields should match";
        }
    }
  },
};
</script>

<style>
.validation-message {
  color: red;
}
</style>
<template>
  <div class="tabs-inner">
    <div class="container">
      <div class="nav-tabs-container">
        <div
          class="square-button add-task-btn"
          data-toggle="modal"
          data-target="#addTaskkModal"
          @click="addTaskModal()"
        >
          Add a support task +
        </div>
        <div class="form-check closed-tasks-toggle">
          <input
            class="form-check-input"
            type="checkbox"
            value="true"
            v-model="closedItems"
            id="closedTasksToggle"
          />
          <label class="form-check-label" for="closedTasksToggle">
            Show closed tasks
          </label>
        </div>
        <div class="filter-container mobile-hide">
          <Datepicker
            v-model="date"
            range
            :enableTimePicker="false"
            dark
            placeholder="Filter by date"
          />
        </div>
        <div v-if="filterActive" class="filter-container mobile-show">
          <Datepicker
            v-model="date"
            range
            :enableTimePicker="false"
            dark
            placeholder="Filter by date"
          />
        </div>
        <div
          @click="
            filterActive == true
              ? (filterActive = false)
              : (filterActive = true)
          "
          :class="
            filterActive == true
              ? 'filter-icon mobile-show active'
              : 'filter-icon mobile-show'
          "
        >
          <svg
            v-if="!filterActive"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="filter"
            class="svg-inline--fa fa-filter fa-w-16"
            role="img"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"
            />
          </svg>
          <svg
            v-if="filterActive"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            />
          </svg>
        </div>
      </div>

      <div class="tab-content">
        <table>
          <tr class="border-bottom header-row">
            <th>
              <div class="sort-array-trigger" @click="switchSort('date')">
                Date created
                <span class="sort-array" :class="dateSort"> > </span>
              </div>
            </th>
            <th>
              <div class="sort-array-trigger" @click="switchSort('name')">
                Task
                <span class="sort-array" :class="nameSort"> > </span>
              </div>
            </th>
            <th>Status</th>
            <th>
              <div class="sort-array-trigger" @click="switchSort('time')">
                Time spent(hrs)
                <span class="sort-array" :class="timeSort"> > </span>
              </div>
            </th>
          </tr>
          <tr
            v-for="task in filteredArray"
            :key="task.id"
            class="task-row"
            @click="viewTaskDetails(task)"
            data-toggle="modal"
            data-target="#taskModal"
          >
            <td>{{ formatDate(task.created_time) }}</td>
            <td>{{ task.name }}</td>
            <td>{{ task.status.name }}</td>
            <td>
              {{ task.time_spent }}
            </td>
          </tr>
          <tr class="task-row">
            <td style="padding-top: 60px"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr class="task-row">
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="utilization-summary">
        <table>
          <!-- <tr>
            <td>Utilisation</td>
            <td>Open tasks({{ Object.keys(openTasksLocal).length }})</td>
            <td>{{ countSummary.openHours }}</td>
          </tr>
          <tr>
            <td></td>
            <td>Closed tasks({{ Object.keys(closedTasksLocal).length }})</td>
            <td>{{ countSummary.closedHours }}</td>
          </tr> -->
          <tr>
            <td>Utilisation</td>
            <td>Total time utilized</td>
            <td style="border-bottom: 4px double;">{{ projectDetails.actual_hours }}</td>
          </tr>
        </table>
      </div>
    </div>
    <!--Task Modal -->
    <div
      class="modal fade"
      id="taskModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="taskModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header-row">
            <h5 class="modal-title" id="taskModalTitle">
              {{ taskModal.title }}
            </h5>
            <div class="task-details-stats">
              <span :style="{ color: taskModal.status.color_code }">
                {{ taskModal.status.name }}
              </span>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row border-bottom pb-10 mb-30">
              <div class="col-md-3 col-6">
                <div class="task-details-stats">
                  Created: {{ formatDate(taskModal.created_time) }} <br />
                  <span class="modal-small-text">{{
                    getSignature(taskModal.description) == ""
                      ? taskModal.created_person
                      : getSignature(taskModal.description)
                  }}</span>
                </div>
              </div>
              <div class="col-md-6 mobile-hide"></div>
              <div class="col-md-3 col-6" style="text-align: right">
                <div class="task-details-stats">
                  Support hours used : {{ taskModal.time_spent }}
                </div>
              </div>
            </div>
            <div class="row border-bottom mb-10 pb-30">
              <div class="col">
                <div class="task-details-stats mb-10 poppins-bold">
                  Description:
                </div>
                <div v-html="taskModal.editedDescription"></div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="task-details-stats mb-10 poppins-bold">
                  Comments:
                </div>
                <div
                  class="task-comment mb-30"
                  v-for="comment in mutatedComments"
                  :key="comment.id"
                >
                  <div class="comment-owner">
                    {{
                      getSignature(comment.content) == ""
                        ? comment.added_person
                        : getSignature(comment.content)
                    }}
                    <br />
                    {{ formatCommentTime(comment.created_time_format) }}
                  </div>
                  <div class="comment-content">
                    <div v-html="comment.editedContent"></div>
                    <div
                      v-for="attachmemt in comment.thirdparty_attachments"
                      :key="attachmemt.entity_id"
                      class="comment-attachments"
                    >
                      <a :href="attachmemt.download_url" target="_blank">
                        <img :src="attachmemt.preview_url" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="comments-container">
                  <div class="task-details-stats mb-10 poppins-bold">
                    New comment:
                  </div>

                  <div v-if="!taskModal.attachments">
                    <div class="comment-box-wrapper">
                      <div class="comment-box margin-none">
                        <QuillEditor
                          theme="snow"
                          contentType="html"
                          v-model:content="taskModal.newComment"
                          @keyup.ctrl.v="pasteEvent"
                        />
                        <button
                          class="attachment-btn"
                          v-on:click="taskModal.attachments = true"
                        >
                          📎 Add attachments
                        </button>
                      </div>
                    </div>

                    <div class="submit-wrapper">
                      <div
                        class="
                          wrap-login100-form-btn
                          support-pack-btn
                          mt-20
                          mb-20
                        "
                      >
                        <div class="login100-form-bgbtn"></div>
                        <button
                          class="login100-form-btn"
                          v-on:click="addComment"
                        >
                          Add comment
                        </button>
                      </div>

                      <div class="loader-submit" v-if="loading == true">
                        <Loader> </Loader>
                      </div>
                    </div>
                  </div>
                  <FileUploader
                    v-if="taskModal.attachments"
                    @upload="uploadFiles"
                    @cancelUpload="taskModal.attachments = false"
                    :selectedFiles="taskModal.fileUploads"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Add Task Modal -->
    <div
      class="modal fade"
      id="addTaskkModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addTaskModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header-row">
            <h5 class="modal-title" id="addTaskModalTitle">Add new task</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row border-bottom pb-10 mb-10">
              <div class="col">
                <div class="task-input-group mb-20">
                  <p class="task-details-stats poppins-bold mb-10">
                    Task name<span style="color: red">*</span>
                  </p>
                  <input
                    type="text"
                    id="newTaskName"
                    v-model="newTask.taskName"
                  />
                </div>
                <div class="task-input-group mb-20">
                  <p class="task-details-stats poppins-bold mb-10">
                    Task description<span style="color: red">*</span>
                  </p>
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="newTask.taskDescription"
                    id="taskDescription"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="submit-wrapper">
                  <div
                    class="wrap-login100-form-btn support-pack-btn mt-20 mb-20"
                  >
                    <div
                      class="login100-form-bgbtn"
                      :disabled="
                        newTask.taskName == '' || newTask.taskDescription == ''
                      "
                    ></div>
                    <button
                      class="login100-form-btn"
                      @click="createTask"
                      :disabled="
                        newTask.taskName == '' || newTask.taskDescription == ''
                      "
                    >
                      Add task
                    </button>
                  </div>
                  <div class="loader-submit" v-if="loading == true">
                    <Loader> </Loader>
                  </div>
                </div>
                <div
                  :class="messageError == true ? 'message error' : 'message'"
                >
                  <p>
                    {{ message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import _ from "lodash";
import DataService from "@/api/DataService";
import Loader from "@/components/loader.vue";
import FileUploader from "@/components/FileUploader.vue";

// import Store from "@/store";

// import moment from 'moment';
export default {
  props: {
    projectDetails: {},
    tasks: {},
    openTasks: {},
    closedTasks: {},
    allTasks: {},
  },
  components: {
    Datepicker,
    Loader,
    FileUploader,
  },
  data() {
    return {
      projectId: 0,
      openItems: true,
      closedItems: false,
      openTasksLocal: {},
      closedTasksLocal: {},
      allTasksLocal: {},
      summary: {
        openHours: 0,
        closedHours: 0,
        totalHours: 0,
      },
      tasksDropdown: false,
      filterActive: false,
      nameSort: "",
      dateSort: "asc",
      timeSort: "",
      sortField: "",
      date: null,
      signature: "{{" + localStorage.getItem("name") + "}}",
      taskModal: {
        id: null,
        created_person: "",
        created_time: "",
        description: "",
        editedDescription: "",
        title: "",
        status: "",
        log_hours: {
          billable_hours: 0,
          non_billable_hours: 0,
        },
        time_spent: 0,
        comments: [],
        newComment: "",
        newCommentOwner: "",
        attachments: false,
        fileUploads: new FormData(),
      },
      newTask: {
        taskOwnerName: "",
        taskName: "",
        taskDescription: "",
      },
      loading: false,
      message: "",
      messageError: false,
    };
  },
  computed: {
    countSummary() {
      let summary = {};
      let openSummary = 0.0;
      for (var i in this.openTasksLocal) {
        openSummary += this.timeToDecimal(
          this.openTasksLocal[i].log_hours.billable_hours
        );
      }
      summary.openHours = openSummary.toFixed(2);

      let closedSummary = 0.0;
      for (var j in this.closedTasksLocal) {
        closedSummary += this.timeToDecimal(
          this.closedTasksLocal[j].log_hours.billable_hours
        );
      }
      summary.closedHours = closedSummary.toFixed(2);
      summary.totalHours = (openSummary + closedSummary).toFixed(2);
      return summary;
    },
    filteredArray() {
      let tasksArray =
        this.closedItems == true ? this.allTasksLocal : this.openTasksLocal;
      tasksArray = JSON.parse(JSON.stringify(tasksArray));
      switch (this.sortField) {
        case "date":
          if (this.dateSort == "asc") {
            tasksArray = _.orderBy(tasksArray, function(o){
              return new Date(o.created_time)
            }, "asc");
  
          } else if (this.dateSort == "desc") {
            tasksArray = _.orderBy(tasksArray, function(o){
              return new Date(o.created_time)
            }, "desc");
          }
          break;
        case "name":
          if (this.nameSort == "asc") {
            tasksArray = _.orderBy(tasksArray, "name", "asc");
          } else if (this.nameSort == "desc") {
            tasksArray = _.orderBy(tasksArray, "name", "desc");
          }
          break;
        case "time":
          if (this.timeSort == "asc") {
            tasksArray = _.orderBy(tasksArray, function(o){
              return parseFloat(o.time_spent)
            }, "asc");
          } else if (this.timeSort == "desc") {
            tasksArray = _.orderBy(tasksArray, function(o){
              return parseFloat(o.time_spent)
            }, "desc");
          }

          break;
        default:
          break;
      }

      if (this.date !== null) {
        let startDate = this.date[0];
        let endDate = this.date[1];
        tasksArray = _.values(tasksArray);
        tasksArray = tasksArray.filter((item) => {
          const itemDate = new Date(item.created_time);
          if (startDate !== null && endDate !== null) {
            return startDate <= itemDate && itemDate <= endDate;
          }
          if (startDate !== null && endDate === null) {
            return startDate <= itemDate;
          }
          if (startDate === null && endDate !== null) {
            return itemDate <= endDate;
          }
          return true; // when neither startDate nor endDate selected
        });
      }

      for (var i in tasksArray) {
        var content = tasksArray[i].description;

        var signature =
          content !== undefined
            ? content.substring(
                content.indexOf("{{") + 2,
                content.lastIndexOf("}}")
              )
            : "";
        var signatureWithBrackets =
          content !== undefined
            ? content.substring(
                content.indexOf("{{"),
                content.lastIndexOf("}}") + 2
              )
            : "";

        let editedDescription =
          signatureWithBrackets.length > 0
            ? content.replace(signatureWithBrackets, signature)
            : content;

        tasksArray[i].editedDescription = editedDescription;
      }

      return tasksArray;
    },
    mutatedComments() {
      let comments = this.taskModal.comments;
      for (var i in comments) {
        var content = comments[i].content;

        var signature = content.substring(
          content.indexOf("{{") + 2,
          content.lastIndexOf("}}")
        );
        var signatureWithBrackets = content.substring(
          content.indexOf("{{"),
          content.lastIndexOf("}}") + 2
        );

        var fullMention = content.substring(
          content.indexOf("zp["),
          content.indexOf("]zp") + 3
        );
        var mentionName = content.substring(
          content.indexOf("zp[") + 22,
          content.indexOf("]zp")
        );

        let editedContent =
          signatureWithBrackets.length > 0
            ? content.replace(signatureWithBrackets, signature)
            : content;
        editedContent =
          fullMention.length > 3
            ? content.replace(fullMention, mentionName)
            : editedContent;

        comments[i].signature = signature;
        comments[i].editedContent = editedContent;
      }
      return comments;
    },
    showTotalInTime(){
      let t = this.projectDetails.actual_hours;
      t = typeof(t) == 'object' ? 0 : t;
      return t != 0 ? this.decimalToTime(t): 0;
    }
  },
  async mounted() {
    let currentProject = JSON.parse(localStorage.getItem("current_project"));
    this.projectId = currentProject.id;
    this.openTasksLocal = this.openTasks;
    this.closedTasksLocal = this.closedTasks;
    this.allTasksLocal = this.allTasks;
    this.switchSort('date')
  },
  methods: {
    timeToDecimal(t) {
      var arr = t.split(".");
      var dec = parseInt((arr[1] / 6) * 10, 10);
      let decimalValue = parseFloat(
        parseInt(arr[0], 10) + "." + (dec < 10 ? "0" : "") + dec
      );
      return decimalValue;
    },
    decimalToTime(t) {
      var arr = t.split(".");
      var minutes = arr[1].length === 1 ? parseInt(arr[1] + "0") : arr[1];
      minutes = parseInt((minutes * 60) / 100);
      let timeValue = (
        parseInt(arr[0], 10) + ":" + (minutes < 10 ? "0" : "") + minutes
      );
      return timeValue;
    },
    pasteEvent() {
      navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
        // If permission to read the clipboard is granted or if the user will
        // be prompted to allow it, we proceed.

        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.read().then((data) => {
            for (let i = 0; i < data.length; i++) {
              if (!data[i].types.includes("image/png")) {
                console.log('blob')
              } else {
                data[i].getType("image/png").then((blob) => {
                
                  this.taskModal.fileUploads.append(
                    "files",
                    blob,
                    URL.createObjectURL(blob) + ".png"
                  );
                });
              }
            }
          });
        }
      });
    },
    switchSort(sortBy) {
      this.sortField = sortBy;
      switch (sortBy) {
        case "date":
          this.dateSort = this.dateSort == "asc" ? "desc" : "asc";
          this.nameSort = "";
          this.timeSort = "";
          break;
        case "name":
          this.nameSort = this.nameSort == "asc" ? "desc" : "asc"
          this.dateSort = "";
          this.timeSort = "";
          break;
        case "time":
          this.timeSort = this.timeSort == "asc" ? "desc" : "asc"
          this.dateSort = "";
          this.nameSort = "";
          break;
        default:
          break;
      }
    },
    viewTaskDetails(task) {
      this.taskModal.id = task.id;
      this.taskModal.created_person = task.created_person;
      this.taskModal.created_time = task.created_time;
      this.taskModal.title = task.name;
      this.taskModal.status = task.status;
      this.taskModal.log_hours = task.log_hours;
      this.taskModal.time_spent = task.time_spent;
      this.taskModal.description = task.description;
      this.taskModal.editedDescription = task.editedDescription;
      if (task.comments) {
        this.taskModal.comments = task.comments;
      } else {
        this.taskModal.comments = [];
      }
    },
    async addComment() {
      this.loading = true;
      this.taskModal.newComment =
        this.taskModal.newComment + "<br/>" + this.signature;
      await DataService.addTaskComment(
        this.taskModal.id,
        this.projectId,
        this.taskModal.newComment,
        this.taskModal.fileUploads
      ).then((response) => {
        if (response.status == 200) {
          this.updateTask(this.taskModal.id);
          this.taskModal.newComment = "";
          var element = document.getElementsByClassName("ql-editor");
          element[0].innerHTML = "";
        }
      });
      this.loading = false;
    },
    getSignature(content) {
      var signature = content.substring(
        content.indexOf("{{") + 2,
        content.lastIndexOf("}}")
      );
      signature = signature.length > 1 ? signature : "";

      return signature;
    },

    async createTask() {
      this.loading = true;
      this.newTask.taskDescription =
        this.newTask.taskDescription + "<br/>" + this.signature;

      await DataService.createTask(
        this.projectId,
        this.newTask.taskName,
        this.newTask.taskDescription
      ).then((response) => {
        if (response.status == 200) {
          this.$emit("updateTasks");
          this.messageError = false;
          this.message = "Task successfully added.";
          this.newTask.taskOwnerName = "";
          this.newTask.taskName = "";
          this.newTask.taskDescription = "";
          var element = document.getElementsByClassName("ql-editor");
          element[1].innerHTML = "";
        } else {
          this.messageError = true;
          this.message = "Error adding task. Please try again.";
        }
      });
      this.loading = false;
    },
    async updateTask(taskId) {
      await DataService.getSingleTask(this.projectId, taskId).then(
        (response) => {
          if (response.length > 0) {
            this.taskModal.comments = response[0].comments;
            let tasksArray =
              this.openItems == true
                ? this.openTasksLocal
                : this.closedTasksLocal;
            tasksArray = JSON.parse(JSON.stringify(tasksArray));

            for (const key in tasksArray) {
              if (tasksArray[key].id == response[0].id) {
                tasksArray[key].comments = response[0].comments;
                break;
              }
            }
            this.openItems
              ? (this.openTasksLocal = tasksArray)
              : (this.closedTasksLocal = tasksArray);
          }
        }
      );
    },
    formatDate(dateStr) {
      var parts = dateStr.split("-");
      return parts[1] + "-" + parts[0] + "-" + parts[2];
    },
    formatCommentTime(timeStr) {
      var sections = timeStr.split(" ");
      var parts = sections[0].split("-");
      return (
        parts[1] +
        "-" +
        parts[0] +
        "-" +
        parts[2] +
        " " +
        sections[1] +
        " " +
        sections[2]
      );
    },
    uploadFiles(formData) {
      this.taskModal.fileUploads = formData;
      this.taskModal.attachments = false;
    },
    addTaskModal() {
      this.message = "";
      this.messageError = false;
    },
  },
};
</script>

<style>
button.attachment-btn {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
span.modal-small-text {
  font-size: 14px;
}
.message p {
  color: green;
}
.message.error p {
  color: red;
}
</style>
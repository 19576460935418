import { createRouter, createWebHistory } from 'vue-router'
import SingleProject from '../views/SingleProject.vue'
import UpdatePassword from '../views/UpdatePassword.vue'
import Welcome from '../views/Welcome.vue'

const routes = [{
        path: '/',
        name: 'Welcome',
        component: Welcome
    },
    {
        path: '/:slug',
        name: 'SingleProject',
        component: SingleProject,
        props: true
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: UpdatePassword
    },
    {
        path: '/support-pack-list',
        name: 'SupportPackList',
        component: () =>
            import ( /* webpackChunkName: "select" */ '../views/SupportPackList.vue')
    },
    {
        path: '/account',
        name: 'Account',
        component: () =>
            import ( /* webpackChunkName: "account" */ '../views/DashboardUI.vue')
    },
]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    var isAuthenticated = localStorage.getItem("isAuthenticated");
     if ((to.name !== "Login" && to.name !== "ResetPassword") && isAuthenticated == "false") {
        next({ name: 'Login' });
    } else {
        next();
    }
})


export default router
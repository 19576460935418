<template>
  <div _ngcontent-serverapp-c61="" class="footer">
    <div _ngcontent-serverapp-c61="" class="container">
      <div _ngcontent-serverapp-c61="" class="footer-wrapper">
        <div _ngcontent-serverapp-c61="" class="footer-logo">
          <img
            _ngcontent-serverapp-c61=""
            src="images/logo_footer.png"
            alt="attractor footer logo"
          />
        </div>
        <div _ngcontent-serverapp-c61="" class="footer-text">
          <div _ngcontent-serverapp-c61="" class="text">T +61 3 8354 4115</div>
          <div _ngcontent-serverapp-c61="" class="text">
            E
            <a
              _ngcontent-serverapp-c61=""
              class="red"
              href="mailto:melbourne@attractor.com.au"
              >melbourne@attractor.com.au</a
            >
          </div>
          <div _ngcontent-serverapp-c61="" class="text">
            © {{new Date().getFullYear()}} Attractor Solutions Pty Ltd. All rights reserved.
          </div>
        </div>
        <div _ngcontent-serverapp-c61="" class="footer-social">
          <div _ngcontent-serverapp-c61="" class="images">
            <a
              _ngcontent-serverapp-c61=""
              tabindex="-1"
              target="_blank"
              class="left"
              href="https://www.facebook.com/attractorsolutions/"
              ><img
                _ngcontent-serverapp-c61=""
                src="images/facebook_footer.png"
                aria-label="Facebook Link"
                alt="Facebook logo" /></a
            ><a
              _ngcontent-serverapp-c61=""
              target="_blank"
              aria-label="Instagram link"
              tabindex="-1"
              class="left"
              href="https://www.instagram.com/attractorsolutions/"
              ><img
                _ngcontent-serverapp-c61=""
                alt="Instagram logo"
                src="images/instagram_footer.png" /></a
            ><a
              _ngcontent-serverapp-c61=""
              target="_blank"
              aria-label="Instagram link"
              tabindex="-1"
              href="https://www.linkedin.com/company/attractorsolutions/"
              ><img
                _ngcontent-serverapp-c61=""
                src="images/linkedin_footer.png"
                alt="Linkedin Logo"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

</style>
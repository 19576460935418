<template>
  <div class="home">
    
    <section>
      <div class="container pt-30 pb-30">
        <div class="row">
          <div cla="col">
            <h1 class="title">{{ brandName }}</h1>
          </div>
        </div>
      </div>
    </section>
    <section
      class="hours-insight"
      style="background-image: url('images/attractor-bg.png')"
    >
      <div class="container mobile-width">
        <div class="row">
          <div class="col-xl-6">
            <!-- <p class="paragraph-large font-white">
              Current pack: {{ latestSupportPack }} hour<span
                v-if="latestSupportPack > 1"
                >s</span
              >
            </p>
            <p v-if="dateInvoiced" class="paragraph-small font-white text-right">
              Invoiced: {{ dateInvoiced }}
            </p> -->
          </div>
          <div class="col-xl-6">
            <p class="paragraph-large font-white text-right">
              Support hours remaining: {{ showRemainingHoursInTime }} hour<span
                v-if="hoursRemaining !== 1"
                >s</span
              >
            </p>
            <p v-if="hoursRemaining <= 2" class="paragraph-small notice">
              Your support pack is running low. Please speak with us to topup.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="items-tabs">
      <item-tabs
        v-bind:projectDetails="projectDetails"
        v-bind:tasks="projectTasks"
        v-bind:openTasks="openTasks"
        v-bind:closedTasks="closedTasks"
        v-bind:allTasks="combinedTasks"
        v-on:updateTasks="getTasks"
      ></item-tabs>
    </section>
    <div class="loader-overlay" v-if="loading == true">
      <Loader> </Loader>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ItemTabs from "@/components/ItemTabs.vue";
import DataService from "@/api/DataService";
import Store from "@/store";
import Loader from "@/components/loader.vue";

// import moment from 'moment';
import router from "@/router";
export default {
  name: "Home",
  data() {
    return {
      // 5002000000593107 - lifestyle
      projectId: null,
      projectDetails: null,
      projectTasks: null,
      openTasks: {},
      closedTasks: {},
      combinedTasks: {},
      brandName: "",
      latestSupportPack: 0,
      dateInvoiced: "",
      tasks: null,
      hoursRemaining: 0,
      loading: true,
    };
  },
  components: {
    // HelloWorld
    ItemTabs,
    Loader,
   
  },
  beforeCreate() {},
  created() {
    var isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!(isAuthenticated == "true")) {
      router.push({ name: "Login" });
    } else {
      this.projectDetails = JSON.parse(localStorage.getItem("current_project"));
      this.projectId = this.projectDetails.id;
    }
  },
  computed: {
    showRemainingHoursInTime(){

      return this.hoursRemaining != 0 ? this.decimalToTime(this.hoursRemaining): 0;
    }
  },
  mounted() {
    this.getTasks();
  },
  methods: {
    decimalToTime(t) {
      var arr = t.split(".");
      var minutes = arr[1].length === 1 ? parseInt(arr[1] + "0") : arr[1];
      minutes = parseInt((minutes * 60) / 100);
      let timeValue = (
        parseInt(arr[0], 10) + ":" + (minutes < 10 ? "0" : "") + minutes
      );
      return timeValue;
    },
    async getTasks() {
      await DataService.getProjectTasks(this.projectId).then((response) => {
        this.projectTasks = response;
      });
      Store.state.tasks = this.tasks;
      Store.state.projectDetails = this.projectDetails;

      if ("custom_fields" in this.projectDetails) {
        var customFields = this.projectDetails.custom_fields;
        customFields.forEach((field) => {
          if (field["Client Brand Name"]) {
            this.brandName = field["Client Brand Name"];
          }
          if (field["Size of latest support pack (no. of hours)"]) {
            this.latestSupportPack =
              field["Size of latest support pack (no. of hours)"];
          }
          if (field["Date Invoiced"]) {
            this.dateInvoiced = field["Date Invoiced"];
          }
        });
      } else {
        this.brandName = this.projectDetails.name;
        this.latestSupportPack = this.projectDetails.budget_value;
      }
      // this.hoursRemaining =
      //   this.latestSupportPack - this.projectDetails.actual_hours;
      this.hoursRemaining = (
        this.projectDetails.budget_value - this.projectDetails.actual_hours
      ).toFixed(2);

      // this.hoursRemaining = -1

      // let budgetValueTime = moment.duration( this.convertNumToTime(this.projectDetails.budget_value), "HH:mm");
      // let actualValueTime = moment.duration(this.convertNumToTime(this.projectDetails.actual_hours), "HH:mm");
      // let remainingTime = budgetValueTime.subtract(actualValueTime);
      // this.hoursRemaining = remainingTime.hours() + ":"+remainingTime.minutes();

      var openCount = 0;
      var closedCount = 0;
      var taskCount = 0;
      this.projectTasks.forEach((task) => {
    
        task.time_spent = parseFloat(task.log_hours.billable_hours).toFixed(2);
        if (task.status.type == "open") {
          this.openTasks[openCount] = task;
          openCount++;
        } else {
          this.closedTasks[closedCount] = task;
          closedCount++;
        }
        this.combinedTasks[taskCount] = task;
        taskCount++;
      });

      this.loading = false;
    },
    convertNumToTime(number) {
      // Check sign of given number
      var sign = number >= 0 ? 1 : -1;

      // Set positive value of number of sign negative
      number = number * sign;

      // Separate the int from the decimal part
      var hour = Math.floor(number);
      var decpart = number - hour;

      var min = 1 / 60;
      // Round to nearest minute
      decpart = min * Math.round(decpart / min);

      var minute = Math.floor(decpart * 60) + "";

      // Add padding if need
      if (minute.length < 2) {
        minute = "0" + minute;
      }

      // Add Sign in final result
      sign = sign == 1 ? "" : "-";

      // Concate hours and minutes
      var time = sign + hour + ":" + minute;

      return time;
    },
  },
};
</script>

<style>
</style>

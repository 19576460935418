import { createStore } from 'vuex'

export default createStore({
    state: {
        auth: {
            token: null,
            name: ""
        },
        userDetails: {
            fname:null,
            lname:null,
            email:null,
            gender:null,
            dataFetched: false
        },
        projectDetails: null,
        allProjectDetails: null,

        projects: {},
        tasks: {}
    },
    mutations: {},
    actions: {},
    modules: {}
})
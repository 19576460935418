<template>
  <div id="welcome">
      <div class="container">
          <div class="text-center pt-30">
              
          </div>
      </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
    methods:{
        login: function() {
            router.push({name:'Login'});
        }
    },
    created() {
        var isAuthenticated = localStorage.getItem('isAuthenticated');
        if(isAuthenticated == 'true'){
            router.push({ name: "SupportPackList" });
        }else{
            this.login();
        }
    }
}
</script>

<style>

</style>
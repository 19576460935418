import auth from "@/api/auth/auth-common.js";
import http from "@/api/http-common.js";

import store from "@/store/index.js"

class DataService {
    async getRefreshToken() {
        await auth.get("/get_token.php").then((response) => {
            store.state.auth.token = response.data.access_token;
            
        });

    }

    async loginUser(user, pass) {
        var data = {
            username: user,
            password: pass
        }

        return await http.post('/user_auth.php', data);

    }
    async generateResetLink(user_email){
        var data = {
            email:user_email
        }

        return await http.post('/generate_reset_link.php', data);
    }
    async fetchUserData(username_hash){
        var data = {
            hashed_username:username_hash
        }
        return await http.post('/get_user_details.php', data);
    }
    async updateUserData(newFname,newLname,newEmail,newGender,hashed_user){
        var data = {
            fname: newFname,
            lname: newLname,
            email: newEmail,
            gender: newGender,
            hashed_username: hashed_user
        }

        return await http.post('/update_user_details.php',data);
    }
    async updatePassword(currentPassword,newPassword,hashedUsername){
        var data = {
            current_password: currentPassword,
            new_password: newPassword,
            hashed_username: hashedUsername
        }

        return await http.post('/update_password.php', data);
    }
    async validateResetLink(user_req, user_cont){
        var data = {
            req:user_req,
            cont:user_cont
        }

        return await http.post('/validate_reset_link.php', data);
    }
    async resetPassword(user_req, user_cont, password){
        var data = {
            req:user_req,
            cont:user_cont,
            new_password:password
        }

        return await http.post('/reset_password.php', data);
    }
    getPortals() {
        return http.get('/portals/');
    }
    async getPortal(id) {
        return await http.get(`/portal/${id}`);
    }
    getProjects() {
        return http.get('/portal/7000507041/projects/');
    }
    async getProject(id) {
        if (store.state.auth.token == null) {
            await this.getRefreshToken();
        }

        var project = await http.get(`get_project.php?id=${id}&token=${store.state.auth.token}`);

        return project.data.projects[0];
    }
    async getProjectDetails(projectIds) {
        if (store.state.auth.token == null) {
            await this.getRefreshToken();
        }
        let data = {
            token: store.state.auth.token,
            project_ids: projectIds
        }
        let projects = await http.post('/get_project_details.php', data);
        return projects;
    }
    async getProjectTasks(id) {
        if (store.state.auth.token == null) {
            await this.getRefreshToken();
        }

        var tasks = await http.get(`get_tasks.php?id=${id}&token=${store.state.auth.token}`);

        return tasks.data.tasks;
    }
    async getSingleTask(projectId, taskId) {
        if (store.state.auth.token == null) {
            await this.getRefreshToken();
        }

        var response = await http.get(`get_single_task.php?project_id=${projectId}&token=${store.state.auth.token}&task_id=${taskId}`);
      
        return response.data.tasks;
    }
    async addTaskComment(taskId, projectId, newComment, formData) {
        formData == null ? new FormData() : formData;
        console.log('formData: ',formData.getAll('files'));
        // let filesToUpload = formData.getAll('files');
        // for(var i=0; i<filesToUpload.length; i++){
        //     formData.append('files[]',filesToUpload[i]);
        // }
        if (store.state.auth.token == null) {
            await this.getRefreshToken();
        }
        // let data = {
        //     token: store.state.auth.token,
        //     project_id: projectId,
        //     task_id: taskId,
        //     comment: newComment,
        // }
        // formData.append("data", JSON.stringify(data));
        formData.append("token", store.state.auth.token);
        formData.append("project_id", projectId);
        formData.append("task_id", taskId);
        formData.append("comment", newComment);
        var response =  await http.post('/add_task_comment.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log('response: ',response);
        return response;
    }
    async createTask(projectId, taskName, taskDescription) {
        if (store.state.auth.token == null) {
            await this.getRefreshToken();
        }
        let data = {
            token: store.state.auth.token,
            project_id: projectId,
            task_name: taskName,
            task_description: taskDescription
        }
       
        return await http.post('/create_task.php', data);
    }
    getCustomFields() {
        return http.get('/portal/7000507041/projects/customfields/');
    }
    getProjectUsers(id) {
        return http.get(`/portal/7000507041/projects/${id}/users/`);
    }

}

export default new DataService();
<template>
  <div id="navbar">
    <nav
      class="
        navbar navbar-dark
        bg-dark
        justify-content-between
        navbar-expand-lg
      "
    >
      <div class="container">
        <div class="navbar-left">
          <div class="navbar-brand-wrapper">
            <a href="/" class="navbar-brand"
              ><img src="images/attractor-logo.png" alt="" srcset=""
            /></a>
          </div>
        </div>
        <div class="navbar-right">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Account
              </a>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/account"
                  >My Profile</router-link
                >
                <a v-if="projectsLength > 1" class="dropdown-item" href="/"
                  >My Support Packs</a
                >
                <div
                  v-if="logged == true && projectsLength > 1"
                  class="dropdown-divider"
                ></div>

                <a
                  v-if="logged == true"
                  class="dropdown-item"
                  href="#"
                  v-on:click="logout"
                  >Logout</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";

export default {
  data() {
    return {
      logged: false,
      projectsLength: 0,
      token : null
    };
  },
 
  mounted() {
    if (localStorage.getItem("isAuthenticated") == "true") {
      this.logged = true;
      this.projectsLength = JSON.parse(localStorage.getItem("projects")).length;
    }
  },
  
  methods: {
    logout() {
      localStorage.setItem("isAuthenticated", "false");
      localStorage.setItem("projectID", null);
      localStorage.setItem("project_details", null);
      localStorage.setItem("slug", null);
      localStorage.setItem("current_project", null);
      localStorage.setItem("projects", null);
      store.state.auth.token = null;
      store.state.projectDetails = null;
      store.state.allProjectDetails = null;
      store.state.projects = {};
      store.state.tasks = {};
      router.push({ name: "Login" });
    },
  },
};
</script>

<style>
</style>